import React, { Suspense, lazy } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Privacy from "./Legal/Privacy";
const LandingPage = lazy(() => import("./LandingPage/LandingPage"));
const Component = lazy(() => import("./Component/Component"));
const Refund = lazy(() => import("./Legal/Refund"));
const Terms = lazy(() => import("./Legal/Terms"));
const Button = lazy(() => import("./Component/Buttons/Button"));
const ComponentBody = lazy(() => import("./Component/ComponentBody"));
const Toggle = lazy(() => import("./Component/Toggle/Toggle"));
const NavBar = lazy(() => import("./Component/NavBars/NavBar"));
const Carousel = lazy(() => import("./Component/Carousels/Carousel"));
const Accordian = lazy(() => import("./Component/Accordians/Accordian"));
const DropDown = lazy(() => import("./Component/DropDowns/DropDown"));
const BoxShadow = lazy(() => import("./Component/BoxShadows/BoxShadow"));
const Service = lazy(() => import("./Service/Service"));
const Work = lazy(() => import("./Work/Work"));
const Contact = lazy(() => import("./BookCall/Contact"));

const Body = () => {
  const appRouter = createBrowserRouter([
    {
      path: "/",

      element: (
        <Suspense fallback={<p></p>}>
          <LandingPage />{" "}
        </Suspense>
      ),
    },
    {
      path: "/work",

      element: (
        <Suspense fallback={<p></p>}>
          {" "}
          <Work />
        </Suspense>
      ),
    },

    {
      path: "/service",

      element: (
        <Suspense fallback={<p></p>}>
          {" "}
          <Service />{" "}
        </Suspense>
      ),
    },
    {
      path: "/contact",

      element: (
        <Suspense fallback={<p></p>}>
          {" "}
          <Contact />
        </Suspense>
      ),
    },
    {
      path: "/component",

      element: (
        <Suspense fallback={<p></p>}>
          <Component />
        </Suspense>
      ),
      children: [
        {
          path: "",
          element: (
            <Suspense fallback={<p></p>}>
              <ComponentBody />
            </Suspense>
          ),
        },
        {
          path: "buttons",
          element: (
            <Suspense fallback={<p></p>}>
              {" "}
              <Button />
            </Suspense>
          ),
        },
        {
          path: "toggle",
          element: (
            <Suspense fallback={<p></p>}>
              {" "}
              <Toggle />{" "}
            </Suspense>
          ),
        },
        {
          path: "navbar",
          element: (
            <Suspense fallback={<p></p>}>
              <NavBar />{" "}
            </Suspense>
          ),
        },
        {
          path: "carousel",
          element: (
            <Suspense fallback={<p></p>}>
              <Carousel />{" "}
            </Suspense>
          ),
        },
        {
          path: "accordian",
          element: (
            <Suspense fallback={<p></p>}>
              <Accordian />{" "}
            </Suspense>
          ),
        },
        {
          path: "boxshadow",
          element: (
            <Suspense fallback={<p></p>}>
              <BoxShadow />{" "}
            </Suspense>
          ),
        },
        {
          path: "dropdown",
          element: (
            <Suspense fallback={<p></p>}>
              {" "}
              <DropDown />{" "}
            </Suspense>
          ),
        },
      ],
    },
    {
      path: "/privacy",
      element: (
        <Suspense fallback={<p></p>}>
          <Privacy />{" "}
        </Suspense>
      ),
    },
    {
      path: "/refund",
      element: (
        <Suspense fallback={<p></p>}>
          <Refund />{" "}
        </Suspense>
      ),
    },
    {
      path: "/refund",
      element: (
        <Suspense fallback={<p></p>}>
          <Refund />{" "}
        </Suspense>
      ),
    },
    {
      path: "/terms",
      element: (
        <Suspense fallback={<p></p>}>
          <Terms />{" "}
        </Suspense>
      ),
    },
  ]);
  return (
    <>
      <div className="">
        <RouterProvider router={appRouter} />
      </div>
    </>
  );
};

export default Body;
