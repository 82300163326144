import React, { Suspense, lazy, useEffect } from "react";
import Header from "../Header";
const BgBox = lazy(() => import("../LandingPage/BgBox"));
const Footer = lazy(() => import("../Footer/Footer"));
const Privacy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Header />
      <Suspense fallback={<p></p>}>
        <BgBox rowNum={4} />
      </Suspense>
      <div
        className="absolute  lg:block z-10 overflow-hidden rotate-45 blur-2xl  lg:px-36"
        aria-hidden="true"
      >
        <div
          className="mx-auto aspect-[1240/678] w-72 lg:w-[45.1875rem] overflow-hidden bg-gradient-to-tr from-purple-400/60 to-purple-200/50 opacity-30"
          style={{
            clipPath: `polygon( 64.1% 74.1%,
              75.64% 87.44%,
53.1% 42.24%,
127.725% 35.239%,
122.265% 0.143%,
54.069% 1.34%,
97.15% 43.55%,
61.404% 63.648%,
60.784% 79.996%,
69.825% 85.701%,
28.476% 21.735%,
20.075% 56.991%,
0.149% 96.701%,
17.363% 97%,
33.948% 94.464%,
10.452% 128.964%,
57.798% 34.398%`,
          }}
        ></div>
      </div>
      <div className=" border-black lg:px-72 px-5 lg:mt-20 text-zinc-800 py-2 mb-16">
        <h1 className="text-4xl font-bold text-zinc-800 tracking-wider">
          Privacy Policy
        </h1>
        <p className="text-lg mt-5">Last updated: June 09, 2024</p>
        <p className="text-lg mt-3 text-justify">
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p className="text-lg mt-3 text-justify">
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy. This Privacy Policy has been
          created with the help of the Privacy Policy Generator.
        </p>
        <section className="mt-5">
          <h1 className="text-3xl font-semibold">
            Interpretation and Definitions
          </h1>
          <h2 className="text-2xl mt-3 font-semibold">Interpretation</h2>
          <p className="mt-4 text-justify">
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>
          <h1 className="text-2xl mt-5 font-semibold">Definitions</h1>
          <h2 className="mt-3">For the purposes of this Privacy Policy:</h2>
          <ul className="mt-3 space-y-5">
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p>
                <b>Account </b> means a unique account created for You to access
                our Service or parts of our Service.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>Affiliate</b> means an entity that controls, is controlled by
                or is under common control with a party, where "control" means
                ownership of 50% or more of the shares, equity interest or other
                securities entitled to vote for election of directors or other
                managing authority.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>Company</b>(referred to as either "the Company", "We", "Us"
                or "Our" in this Agreement) refers to AceingDev.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>Cookies </b>are small files that are placed on Your computer,
                mobile device or any other device by a website, containing the
                details of Your browsing history on that website among its many
                uses.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p>
                <b>Country </b>refers to: Karnataka, India
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>Device </b>means any device that can access the Service such
                as a computer, a cellphone or a digital tablet.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>Personal Data</b> is any information that relates to an
                identified or identifiable individual
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p>
                <b>Service </b> refers to the Website.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>Service Provider</b>means any natural or legal person who
                processes the data on behalf of the Company. It refers to
                third-party companies or individuals employed by the Company to
                facilitate the Service, to provide the Service on behalf of the
                Company, to perform services related to the Service or to assist
                the Company in analyzing how the Service is used.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>Usage Data</b>refers to data collected automatically, either
                generated by the use of the Service or from the Service
                infrastructure itself (for example, the duration of a page
                visit).
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p>
                <b>Website </b> refers to AceingDev, accessible from{" "}
                <a className="text-blue-600" href="https://aceingdev.com">
                  https://aceingdev.com
                </a>
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>You </b>means the individual accessing or using the Service,
                or the company, or other legal entity on behalf of which such
                individual is accessing or using the Service, as applicable.
              </p>
            </li>
          </ul>
        </section>
        <section className="mt-5">
          <h1 className="text-3xl font-bold ">
            Collecting and Using Your Personal Data
          </h1>
          <p className="text-2xl font-bold mt-3">Types of Data Collected</p>
          <h2 className="text-xl font-semibold mt-3">Personal Data</h2>
          <p className="mt-3 text-justify">
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul className="mt-3 space-y-4">
            {" "}
            <li className="flex gap-x-3 ml-5">
              <p>•</p> <p>Email address</p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p> <p>First name and last name</p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p> <p>Phone number</p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p> <p>Usage Data</p>
            </li>
          </ul>
          <h2 className="text-xl font-semibold mt-3">Usage Data</h2>
          <ul className="mt-3 space-y-4">
            <li>
              Usage Data is collected automatically when using the Service.
            </li>
            <li className="text-justify">
              Usage Data may include information such as Your Device's Internet
              Protocol address (e.g. IP address), browser type, browser version,
              the pages of our Service that You visit, the time and date of Your
              visit, the time spent on those pages, unique device identifiers
              and other diagnostic data.
            </li>
            <li className="text-justify">
              When You access the Service by or through a mobile device, We may
              collect certain information automatically, including, but not
              limited to, the type of mobile device You use, Your mobile device
              unique ID, the IP address of Your mobile device, Your mobile
              operating system, the type of mobile Internet browser You use,
              unique device identifiers and other diagnostic data.
            </li>
            <li className="text-justify">
              We may also collect information that Your browser sends whenever
              You visit our Service or when You access the Service by or through
              a mobile device.
            </li>
          </ul>
          <h2 className="text-xl font-semibold mt-3">
            Tracking Technologies and Cookies
          </h2>
          <p className="mt-3 text-justify">
            We use Cookies and similar tracking technologies to track the
            activity on Our Service and store certain information. Tracking
            technologies used are beacons, tags, and scripts to collect and
            track information and to improve and analyze Our Service. The
            technologies We use may include:
          </p>
          <ul className="mt-5">
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>Cookies or Browser Cookies.</b>A cookie is a small file
                placed on Your Device. You can instruct Your browser to refuse
                all Cookies or to indicate when a Cookie is being sent. However,
                if You do not accept Cookies, You may not be able to use some
                parts of our Service. Unless you have adjusted Your browser
                setting so that it will refuse Cookies, our Service may use
                Cookies.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>Web Beacons.</b>Certain sections of our Service and our
                emails may contain small electronic files known as web beacons
                (also referred to as clear gifs, pixel tags, and single-pixel
                gifs) that permit the Company, for example, to count users who
                have visited those pages or opened an email and for other
                related website statistics (for example, recording the
                popularity of a certain section and verifying system and server
                integrity).
              </p>
            </li>
          </ul>
          <p className="mt-3 text-justify">
            Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies
            remain on Your personal computer or mobile device when You go
            offline, while Session Cookies are deleted as soon as You close Your
            web browser. You can learn more about cookies on TermsFeed website
            article.
          </p>
          <p className="mt-3 text-justify">
            We use both Session and Persistent Cookies for the purposes set out
            below:
          </p>
          <ul className="space-y-4 mt-5">
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <div>
                <p>
                  {" "}
                  <b>Necessary / Essential Cookies</b>
                </p>
                <p className="mt-3"> Type: Session Cookies</p>
                <p className="mt-3"> Administered by: Us </p>
                <p className="mt-3 text-justify">
                  Purpose: These Cookies are essential to provide You with
                  services available through the Website and to enable You to
                  use some of its features. They help to authenticate users and
                  prevent fraudulent use of user accounts. Without these
                  Cookies, the services that You have asked for cannot be
                  provided, and We only use these Cookies to provide You with
                  those services.
                </p>
              </div>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <div>
                <p>
                  {" "}
                  <b>Cookies Policy / Notice Acceptance Cookies</b>
                </p>
                <p className="mt-3">Type: Persistent Cookies</p>
                <p className="mt-3">Administered by: Us</p>
                <p className="mt-3 text-justify">
                  Purpose: These Cookies identify if users have accepted the use
                  of cookies on the Website.
                </p>
              </div>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <div>
                <p>
                  {" "}
                  <b>Functionality Cookies</b>
                </p>
                <p className="mt-3">Type: Persistent Cookies</p>
                <p className="mt-3">Administered by: Us</p>
                <p className="mt-3 text-justify">
                  Purpose: These Cookies allow us to remember choices You make
                  when You use the Website, such as remembering your login
                  details or language preference. The purpose of these Cookies
                  is to provide You with a more personal experience and to avoid
                  You having to re-enter your preferences every time You use the
                  Website.
                </p>
              </div>
            </li>
            <p className="mt-3 text-justify">
              For more information about the cookies we use and your choices
              regarding cookies, please visit our Cookies Policy or the Cookies
              section of our Privacy Policy.
            </p>
          </ul>
        </section>
        <section className="mt-5">
          <h1 className="text-2xl font-bold ">Use of Your Personal Data </h1>
          <p className="mt-3 text-justify">
            The Company may use Personal Data for the following purposes:
          </p>
          <ul className="mt-3 space-y-4">
            {" "}
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>To provide and maintain our Service:</b> including to monitor
                the usage of our Service.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>To manage Your Account:</b> to manage Your registration as a
                user of the Service. The Personal Data You provide can give You
                access to different functionalities of the Service that are
                available to You as a registered user.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>For the performance of a contract:</b> the development,
                compliance and undertaking of the purchase contract for the
                products, items or services You have purchased or of any other
                contract with Us through the Service.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>To contact You:</b> To contact You by email, telephone calls,
                SMS, or other equivalent forms of electronic communication, such
                as a mobile application's push notifications regarding updates
                or informative communications related to the functionalities,
                products or contracted services, including the security updates,
                when necessary or reasonable for their implementation.
              </p>
            </li>
            <p className="text-justify">
              We may share Your personal information in the following
              situations:
            </p>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>With Service Providers:</b> We may share Your personal
                information with Service Providers to monitor and analyze the
                use of our Service, to contact You.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>For business transfers:</b> We may share or transfer Your
                personal information in connection with, or during negotiations
                of, any merger, sale of Company assets, financing, or
                acquisition of all or a portion of Our business to another
                company.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>With Affiliates:</b> We may share Your information with Our
                affiliates, in which case we will require those affiliates to
                honor this Privacy Policy. Affiliates include Our parent company
                and any other subsidiaries, joint venture partners or other
                companies that We control or that are under common control with
                Us.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>With business partners:</b> We may share Your information
                with Our business partners to offer You certain products,
                services or promotions.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>With other users:</b> when You share personal information or
                otherwise interact in the public areas with other users, such
                information may be viewed by all users and may be publicly
                distributed outside.
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                <b>With Your consent:</b> We may disclose Your personal
                information for any other purpose with Your consent.
              </p>
            </li>
          </ul>
          <h1 className="text-2xl font-bold mt-5 ">
            Retention of Your Personal Data{" "}
          </h1>
          <p className="mt-3 text-justify">
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.{" "}
          </p>
          <p className="mt-3 text-justify">
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <h1 className="text-2xl font-bold mt-5 ">
            Transfer of Your Personal Data{" "}
          </h1>
          <p className="mt-3 text-justify">
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.{" "}
          </p>
          <p className="mt-3 text-justify">
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p className="mt-3 text-justify">
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <h1 className="text-2xl font-bold mt-5 ">
            Delete Your Personal Data{" "}
          </h1>
          <p className="mt-3 text-justify">
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>
          <p className="mt-3 text-justify">
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>
          <p className="mt-3 text-justify">
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>
          <p className="mt-3 text-justify">
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>
          <h1 className="text-2xl font-bold mt-5 ">
            Disclosure of Your Personal Data
          </h1>
          <h1 className="text-2xl font-bold mt-5 ">Business Transactions</h1>
          <p className="mt-5 text-justify">
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <h1 className="text-2xl font-bold mt-5 ">Law enforcement </h1>
          <p className="mt-5 text-justify">
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <h1 className="text-2xl font-bold mt-5 ">Other legal requirements</h1>
          <p className="mt-5 text-justify">
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul className="mt-3 space-y-4">
            {" "}
            <li className="flex gap-x-3 ml-5">
              <p>•</p> <p>Comply with a legal obligation</p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                Protect and defend the rights or property of the Company
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                Prevent or investigate possible wrongdoing in connection with
                the Service
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                Protect the personal safety of Users of the Service or the
                public
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p> <p>Protect against legal liability</p>
            </li>
          </ul>
          <h1 className="text-2xl font-bold mt-5 ">
            Security of Your Personal Data
          </h1>
          <p className="mt-5 text-justify">
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
        </section>
        <section className="mt-5">
          <h1 className="text-3xl font-bold ">Children's Privacy</h1>
          <p className="mt-3 text-justify">
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p className="mt-3 text-justify">
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <h1 className="text-3xl mt-5 font-bold ">Links to Other Websites</h1>
          <p className="mt-3 text-justify">
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p className="mt-3 text-justify">
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h1 className="text-3xl mt-5 font-bold ">
            Changes to this Privacy Policy
          </h1>
          <p className="mt-3 text-justify">
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p className="mt-3 text-justify">
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p className="mt-3 text-justify">
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h1 className="text-3xl mt-5 font-bold ">Contact Us</h1>
          <p className="mt-3 text-justify">
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <ul className="mt-3 space-y-4">
            {" "}
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                By visiting this page on our website:{" "}
                <a className="text-blue-600" href="https://aceingdev.com">
                  https://aceingdev.com
                </a>
              </p>
            </li>
            <li className="flex gap-x-3 ml-5">
              <p>•</p>{" "}
              <p className="text-justify">
                Protect and defend the rights or property of the Company
              </p>
            </li>
          </ul>
        </section>
      </div>
      <Suspense fallback={<p></p>}>
        <Footer />
      </Suspense>
    </>
  );
};

export default Privacy;
