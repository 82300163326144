import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import Ad_bg from "../Images/AD_bg.png";
const Header = () => {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const path = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const handleMenuClick = (path) => {
    if (location.pathname === path) {
      // window.location.reload();
      setMenuOpen(false);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(
        (prevScrollPos > currentScrollPos || currentScrollPos < 50) &&
          currentScrollPos < 18000
      );
      setPrevScrollPos(currentScrollPos);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <>
      {!menuOpen ? (
        <motion.div
          initial={{ y: 0, opacity: 1 }}
          animate={{ y: visible ? 0 : -200 }}
          transition={{ y: { duration: 0.6 }, opacity: { duration: 0.6 } }}
          // ref={headerRef}
          className={`sticky   z-30 top-0 border-b border-gray-200 w-full h-fit flex  justify-between py-6 bg-white/[0.4]   ${
            visible ? "opacity-100" : "opacity-0"
          } ${menuOpen ? "bg-white" : "bg-white/[0.4]"}`}
        >
          <div className=" flex lg:ml-10 ml-2 justify-center items-center ">
            <img
              alt=""
              className="border border-[#7600BC] rounded-md w-8 lg:w-9"
              src={Ad_bg}
            />
            <h1 className=" border-black text-xl font-bold tracking-wider mb-1 ml-2 text-zinc-800">
              <Link to="/">
                Aceing
                <motion.span className="text-[#7600BC]">Dev</motion.span>
              </Link>
            </h1>
          </div>

          <ul className="  hidden border-black lg:flex gap-x-14 font-semibold text-zinc-800 items-center">
            <li
              className={`hover:text-[#7600BC] ${
                path.pathname === "/"
                  ? "text-[#7600Bc] border-b-2 border-[#7600BC]"
                  : ""
              }`}
            >
              <Link to="/">Home</Link>
            </li>
            <li
              className={`hover:text-[#7600BC] ${
                path.pathname === "/work"
                  ? "text-[#7600Bc] border-b-2 border-[#7600BC]"
                  : ""
              }`}
            >
              <Link to="/work">Work</Link>
            </li>
            <li
              className={`hover:text-[#7600BC] ${
                path.pathname === "/service"
                  ? "text-[#7600Bc] border-b-2 border-[#7600BC]"
                  : ""
              }`}
            >
              <Link to="/service">Services</Link>
            </li>

            <li
              className={`hover:text-[#7600BC] ${
                path.pathname === "/component"
                  ? "text-[#7600Bc] border-b-2 border-[#7600BC]"
                  : ""
              }`}
            >
              <Link to="/component">Components</Link>
            </li>
          </ul>
          <FontAwesomeIcon
            className="lg:hidden w-5 h-8 text-black mr-3"
            icon={menuOpen ? faXmark : faBars}
            onClick={() => setMenuOpen(!menuOpen)}
          />
          <motion.div className="hidden  relative lg:flex justify-center  lg:mr-10 ">
            <motion.div
              className="flex justify-center"
              whileTap={{
                scale: 0.9,
              }}
            >
              <Link to="/contact">
                <motion.button className="text-sm shadow-2xl shadow-black  rounded-xl font-semibold text-white bg-black px-2 py-1">
                  <div className="flex items-center pl-2">
                    Book a Call
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 mt-1  border-red-500 "
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M10.75 8.75L14.25 12L10.75 15.25"
                        pathLength="1"
                        strokeDashoffset="0px"
                        strokeDasharray="1px 1px"
                      ></path>
                    </svg>
                  </div>
                </motion.button>
              </Link>
              <div className="absolute  h-[2px] w-[4rem] -bottom-[1px] bg-gradient-to-r from-transparent via-yellow-400 to-transparent"></div>
            </motion.div>
          </motion.div>
        </motion.div>
      ) : (
        ""
      )}
      {menuOpen && (
        <div
          className={`h-screen w-screen fixed  flex flex-col justify-between items-center z-50  border-red-500  lg:hidden right-0 left-0  bg-white  transition duration-500 ease-in-out transform translate-x-0`}
        >
          <div className="absolute top-0 w-full flex justify-between h-fit py-6 ">
            <div
              className=" flex lg:ml-10 justify-center items-center ml-2"
              onClick={() => handleMenuClick("/")}
            >
              <img
                alt=""
                className="w-8 lg:w-9 border border-[#7600BC] rounded-md "
                src={Ad_bg}
              />
              <h1 className=" border-black text-xl font-bold tracking-wider mb-1 ml-2 text-zinc-800">
                <Link to="/">
                  Aceing
                  <motion.span className="text-[#7600BC]">Dev</motion.span>
                </Link>
              </h1>
            </div>
            <FontAwesomeIcon
              className=" w-5 h-8 text-black mr-3"
              icon={faXmark}
              onClick={() => setMenuOpen(false)}
            />
          </div>

          <ul className="h-screen w-screen border-black font-bold space-y-6 text-center text-zinc-600 text-2xl  grid place-content-center">
            <motion.li
              onClick={() => handleMenuClick("/")}
              className="px-8 py-2"
              initial={{
                y: 10,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                duration: 0.3,
                delay: 0,
              }}
            >
              <Link to="/">Home</Link>
            </motion.li>
            <motion.li
              onClick={() => handleMenuClick("/work")}
              className="px-4 py-2"
              initial={{
                y: 10,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                duration: 0.3,
                delay: 0.2,
              }}
            >
              <Link to="/work">Work</Link>
            </motion.li>
            <motion.li
              onClick={() => handleMenuClick("/service")}
              className="px-4 py-2"
              initial={{
                y: 10,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                duration: 0.3,
                delay: 0.4,
              }}
            >
              <Link to="/service">Service</Link>
            </motion.li>

            <motion.li
              onClick={() => handleMenuClick("/component")}
              className="px-4 py-2"
              initial={{
                y: 10,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                duration: 0.3,
                delay: 1,
              }}
            >
              <Link to="/component">Component</Link>
            </motion.li>
            <motion.li
              className="relative flex justify-center"
              whileTap={{
                scale: 0.9,
              }}
              initial={{
                y: 100,
                opacity: 0,
              }}
              animate={{
                y: 0,
                opacity: 1,
              }}
              transition={{
                duration: 0.3,
                delay: 1.25,
              }}
            >
              <Link to="/contact" onClick={() => handleMenuClick("/contact")}>
                <motion.button className="text-sm shadow-2xl shadow-black  rounded-xl font-semibold text-white bg-black px-5 py-2">
                  <div className="flex items-center pl-2">
                    Book a Call
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 mt-1  border-red-500 "
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="1.5"
                        d="M10.75 8.75L14.25 12L10.75 15.25"
                        pathLength="1"
                        strokeDashoffset="0px"
                        strokeDasharray="1px 1px"
                      ></path>
                    </svg>
                  </div>
                </motion.button>
              </Link>
              <div className="absolute  h-[2px] w-[4rem] -bottom-[1.5px] bg-gradient-to-r from-transparent via-yellow-400 to-transparent"></div>
            </motion.li>
          </ul>
        </div>
      )}
    </>
  );
};

export default Header;
