import Body from "./Components/Body";

function App() {
  return (
    <>
      <div>
        <Body />
      </div>
    </>
  );
}

export default App;
